import React, { useState } from "react"
import { Box, Typography, Avatar, Card, CardContent, Button, Collapse } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"

const ArticleCard = ({ title, content, author, avatar }) => {
  const [expanded, setExpanded] = useState(false)

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  return (
    <Card
      sx={{
        borderRadius: "16px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        transition: "transform 0.3s ease-in-out",
        "&:hover": {
          transform: "translateY(-5px)",
        },
        width: "100%",
        maxWidth: { xs: "400px", md: "100%" },
      }}
    >
      <CardContent>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333", mb: 2 }}>
          {title}
        </Typography>
        <Collapse in={expanded} collapsedSize="80px">
          <Typography
            variant="body2"
            sx={{
              color: "#555",
              lineHeight: 1.6,
              mb: 2,
            }}
          >
            {content}
          </Typography>
        </Collapse>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar src={avatar} alt={author} sx={{ marginRight: 2, width: 32, height: 32 }} />
            <Typography variant="body2" sx={{ color: "#777" }}>
              Escrito por: <strong>{author}</strong>
            </Typography>
          </Box>
          <Button
            onClick={toggleExpand}
            endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            sx={{ minWidth: "120px" }}
          >
            {expanded ? "Leer menos" : "Leer más"}
          </Button>
        </Box>
      </CardContent>
    </Card>
  )
}

export default ArticleCard

