import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import Navbar from './Navbar';

const Layout = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        background: `linear-gradient(180deg, rgba(245, 245, 245, 0.9) 0%, rgb(200, 220, 240) 40%, rgba(230, 230, 230, 1) 100%)`,
        backgroundAttachment: 'fixed',
        overflow: 'hidden',
      }}
    >
      <CssBaseline />
      <Navbar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
          mt: { xs: '54px', sm: '64px' },
          overflow: 'auto',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
