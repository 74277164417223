import React, { useState, useRef, useEffect } from "react"
import { Box } from "@mui/material"

const VideoPlayer = ({ src }) => {
  const [isPlaying, setIsPlaying] = useState(true)
  const videoRef = useRef(null)

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => console.error("Error autoplaying video:", error))
    }
  }, [])

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
      } else {
        videoRef.current.play().catch((error) => console.error("Error playing video:", error))
      }
      setIsPlaying(!isPlaying)
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "400px",
        height: "80vh",
        maxHeight: "800px",
        backgroundColor: "#000",
        borderRadius: "16px",
        overflow: "hidden",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
        cursor: "pointer",
      }}
      onClick={togglePlayPause}
    >
      <video
        ref={videoRef}
        loop
        playsInline
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Box>
  )
}

export default VideoPlayer

