import React, { useState, useEffect } from "react"
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import VideoPlayer from "./VideoPlayer"
import ArticleCard from "./ArticleCard"
import articles from "./article_data"

export default function Home() {
  const [showVideo, setShowVideo] = useState(true)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  useEffect(() => {
    const checkTime = () => {
      const now = new Date()
      const midnight = new Date()
      midnight.setHours(24, 0, 0, 0)

      if (now >= midnight) {
        setShowVideo(false)
      }
    }

    const timer = setInterval(checkTime, 1000)

    return () => clearInterval(timer)
  }, [])

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              position: "sticky",
              top: { xs: 16, sm: 24, md: 32 },
              zIndex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant={isMobile ? "h5" : "h4"}
              sx={{
                mb: 2,
                fontWeight: "bold",
                color: "#333",
                textAlign: "center",
                width: "100%",
              }}
            >
              Virgen de la Altagracia
            </Typography>
            {showVideo && (
              <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <VideoPlayer src="/videos/virgenaltagracia.mp4" />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant={isMobile ? "h6" : "h5"}
            sx={{
              mb: 3,
              fontWeight: "bold",
              color: "#333",
              textAlign: { xs: "center", md: "left" },
            }}
          >
            Artículos Relacionados
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              alignItems: { xs: "center", md: "stretch" },
            }}
          >
            {articles.map((article) => (
              <ArticleCard
                key={article.id}
                title={article.title}
                content={article.content}
                author={article.author}
                avatar={article.avatar}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

