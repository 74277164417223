const articles = [
    {
      id: 1,
      title: "La Fe en la Virgen de la Altagracia",
      content:
        "Cada 21 de enero, la República Dominicana celebra el Día de la Virgen de la Altagracia, considerada la madre protectora y espiritual del pueblo dominicano. Esta fecha es un momento de reflexión y fe para millones de creyentes, quienes rinden homenaje a la Virgen en todo el país. La tradición incluye misas solemnes, procesiones y actos culturales que destacan la profunda conexión espiritual del pueblo dominicano con su protectora celestial.",
      author: "Comunidad Católica Dominicana",
      avatar: "/images/virgen-altagracia.jpg",
    },
    {
      id: 2,
      title: "Festividades en la Basílica de Higüey",
      content:
        "La Basílica de Higüey, ubicada en la provincia de La Altagracia, es el epicentro de las celebraciones en honor a la Virgen de la Altagracia. Miles de fieles acuden cada año para participar en las actividades religiosas, como la misa solemne y la procesión con la imagen de la Virgen. Este lugar icónico, que combina arquitectura moderna con simbolismo religioso, se convierte en un espacio de encuentro espiritual y celebración cultural. Las festividades incluyen cánticos, rezos y momentos de profunda devoción, reflejando la unidad y fe del pueblo dominicano.",
      author: "Juan Pérez",
      avatar: "/images/basilica-higuey.jpg",
    },
    {
      id: 3,
      title: "Historia y Orígenes de la Virgen de la Altagracia",
      content:
        "La devoción a la Virgen de la Altagracia tiene raíces profundas en la historia de la República Dominicana. Según la tradición, la imagen de la Virgen llegó al país a principios del siglo XVI, traída por colonos españoles. Desde entonces, ha sido venerada como un símbolo de esperanza y protección. Su imagen, que representa a la Virgen María con el Niño Jesús, está rodeada de un aura de misterio y milagros que refuerzan la fe de sus devotos.",
      author: "Historiador Católico",
      avatar: "/images/origenes-altagracia.jpg",
    },
    {
      id: 4,
      title: "Impacto Cultural y Espiritual de la Virgen de la Altagracia",
      content:
        "La Virgen de la Altagracia no solo es un símbolo religioso, sino también un ícono cultural para la República Dominicana. Su día conmemorativo une a las comunidades a través de actividades que incluyen música, danzas tradicionales y expresiones artísticas que celebran la identidad dominicana. Esta mezcla de espiritualidad y cultura convierte a la Virgen en un puente entre la fe y las raíces del pueblo dominicano, fortaleciendo su sentido de pertenencia y unidad.",
      author: "María González",
      avatar: "/images/impacto-cultural.jpg",
    },
  ];
  
  export default articles;
  