// App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';

// Componentes y Páginas
import Layout from './components/Layout';
import Home from './components/Home';
import { registerVisit } from './services/visitapi';
import SEO from './components/SEO'; 

// Tema personalizado
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

const App = () => {
  useEffect(() => {
    const recordVisit = async () => {
      try {
        const response = await registerVisit();
        console.log(response.message);
      } catch (error) {
        console.error('Error al registrar la visita:', error);
      }
    };

    recordVisit();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* Añadimos el componente SEO para gestionar las etiquetas meta */}
      <SEO />
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </Layout>
      </Router>
    </ThemeProvider>
  );
};

export default App;
