import React from "react"
import { AppBar, Toolbar, Typography, Box, Tooltip, useMediaQuery, useTheme } from "@mui/material"
import { styled, keyframes } from "@mui/material/styles"
import IosShareIcon from "@mui/icons-material/IosShare"
import { Link } from "react-router-dom"

// Import the logo image
import logoImage from "../Imagen/logo192.png" // Adjust the path as needed

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  background: `linear-gradient(45deg, rgb(58, 71, 84), rgb(82, 89, 94))`,
  backdropFilter: "blur(10px)",
  overflow: "hidden",
  boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  borderBottom: "1px solid rgba(150, 150, 150, 0.3)",
}))

const GlassButton = styled("button")(({ theme }) => ({
  color: "#ffffff",
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  backdropFilter: "blur(5px)",
  borderRadius: "20px",
  padding: "8px 16px",
  transition: "all 0.3s ease",
  border: "none",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
  },
}))

const shineAnimation = keyframes`
  0% {
    transform: translateX(-100%) rotate(45deg);
  }
  50%, 100% {
    transform: translateX(100%) rotate(45deg);
  }
`

const LogoContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "40px",
  height: "40px",
  marginRight: "5px",
  borderRadius: "50%",
  overflow: "hidden",
  "&::before": {
    content: '""',
    position: "absolute",
    top: "-50%",
    left: "-50%",
    width: "200%",
    height: "200%",
    background:
      "linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0) 100%)",
    transform: "rotate(45deg)",
    animation: `${shineAnimation} 3s infinite`,
  },
}))

const Logo = styled("img")(({ theme }) => ({
  width: "100%",
  height: "100%",
  objectFit: "cover",
}))

const Navbar = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "TutaWin",
          url: window.location.href,
        })
        .then(() => {
          console.log("Thanks for sharing!")
        })
        .catch(console.error)
    } else {
      navigator.clipboard
        .writeText(window.location.href)
        .then(() => {
          alert("Link copied to clipboard!")
        })
        .catch(console.error)
    }
  }

  return (
    <StyledAppBar position="fixed">
      <Toolbar sx={{ justifyContent: "space-between", padding: 0, minHeight: 64, position: "relative" }}>
        <Box sx={{ display: "flex", alignItems: "center", zIndex: 1 }}>
          <Link to="/" style={{ textDecoration: "none", color: "inherit", display: "flex", alignItems: "center" }}>
            <LogoContainer>
              <Logo src={logoImage} alt="TutaWin Logo" />
            </LogoContainer>
            <Typography
              variant="h5"
              noWrap
              component="div"
              sx={{
                fontWeight: "bold",
                textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
                background: "linear-gradient(45deg, #e0f2f1, #b2dfdb)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                cursor: "pointer",
              }}
            >
              TutaWin
            </Typography>
          </Link>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: "flex", alignItems: "center", zIndex: 1 }}>
          <Tooltip title="Compartir">
            <GlassButton
              onClick={handleShare}
              sx={{
                mr: 1,
                [theme.breakpoints.down("sm")]: {
                  padding: "6px 12px",
                  fontSize: "0.8rem",
                },
              }}
            >
              <IosShareIcon sx={{ mr: isMobile ? 0 : 1 }} />
              {isMobile ? "" : "Compartir"}
            </GlassButton>
          </Tooltip>
        </Box>
      </Toolbar>
    </StyledAppBar>
  )
}

export default Navbar

